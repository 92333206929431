import Vue from "vue"

Vue.filter("tableHeaderSpace", str => {
    switch (str) {
        case 'Inclusion':
            return '% Inclusion';
        case 'INCIName':
            return 'INCI Name';
        case 'CucCode1_Percentage':
            return '% Inclusion of CUC Code1';
        case 'CucCode2_Percentage':
            return '% Inclusion of CUC Code2';
        case 'ColorIndicator':
            return 'Status';
        case 'ReasonChange':
            return 'Reasons for Change';
        case 'PercentageInclusion':
            return '% Inclusion';
        case 'CucDescription':
            return 'Description';
        case 'CucRevision':
            return 'Revision';
        case 'PlmMaterialId':
            return 'CUC Code';
        case 'CucStatusText':
            return 'CUC Status';
        case 'IUPACName':
            return 'IUPAC Name';
        //case 'CASNumber':
            //return 'CAS Number';
            
        case 'EINECSNumber':
            return 'EINECS Number';

    }
    return str.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
});

Vue.filter("cucUpperCase", str => {
    return str.replace('Cuc', 'CUC');
});
Vue.filter("formatDate", str => {
    if (str === null || str === undefined) return;
    return str.split("T")[0];
});
